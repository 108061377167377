.margin-bottom-text{
    margin: 25px 0; 
}
.new-gallery .inline-YTPlayer {
    position: absolute !important;
    max-width: 350px !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.new-gallery .mb_YTPlayer{
    height: 100% !important;

}

