.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
}

.homepage-boxes {
	background-color: #262626;
	&_white {
		padding-bottom: 50px;
		background-color: #fff;
		@include respond-to('extra-small') {
			padding-bottom: 25px;
		}
		& .home-buttons {
			// box-shadow: 0 0 0 2px rgba($color: #fff, $alpha: 1.0);
		}
	}
	&__item {
		float: $right;
		width: 50%;
		height: 600px;
		padding: 1px;
		@include respond-to('larger') {
			height: 500px;
		}
		@include respond-to('large') {
			height: 450px;
		}
		@include respond-to('medium') {
			height: 350px;
		}
		@include respond-to('small') {
			height: 300px;
		}
		@include respond-to('extra-small') {
			width: 100%;
			height: 250px;
		}
		&_small {
			width: 33.33%;
			height: 450px;
			@include respond-to('larger') {
				height: 350px;
			}
			@include respond-to('larger') {
				height: 300px;
			}
			@include respond-to('small') {
				width: 50%;
			}
			@include respond-to('extra-small') {
				width: 100%;
				height: 250px;
			}
		}
	}
}

.hp-about {
	padding: 45px 0;
	background-color: #262626;
	text-align: center;
	@include respond-to('small') {
		padding: 25px 0;
	}
	&__title {
		margin-bottom: 35px;
		font-size: 40px;
		font-weight: 400;
		color: #fff;
		line-height: 0.9;
		@include respond-to('medium') {
			font-size: 35px;
			margin-bottom: 30px;
		}
		@include respond-to('small') {
			font-size: 30px;
			margin-bottom: 25px;
		}
		@include respond-to('extra-small') {
			font-size: 30px;
			margin-bottom: 15px;
		}
		& span {
			display: block;
			font-weight: 700;
		}
	}
	&__text {
		text-align: center;
		& p {
			margin: 0;
			font-size: 20px;
			font-weight: 300;
			color: #fff;
			line-height: 1.2;
			@include respond-to('small') {
				font-size: 18px;
			}
			@include respond-to('extra-small') {
				font-size: 16px;
				text-align: justify;
			}
		}
	}
}