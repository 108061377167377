.cat-nav {
	height: 210px;
	width: 100%;
	font-size: 0;
	background-color: #262626;
	@include respond-to('small') {
		text-align: center;
	}
	@include respond-to('small') {
		height: 180px;
	}
	@include respond-to('extra-small') {
		height: 150px;
	}
	& .section-limit,
	& .cat-nav-slider,
	& .slick-track,
	& .slick-list {
		height: 100%;
	}
	&__item {
		width: 12.5%;
		height: 100%;
		display: inline-block;
		vertical-align: top;
		font-size: initial;
		overflow: hidden;
		@include respond-to('small') {
			// width: 33.33%;
			// height: 165px;
		}
		@include respond-to('extra-small') {
			// width: 50%;
			// height: 112px;
			&:last-of-type {
				width: 100%;
			}
		}
	}
}

.cat-item {
	height: 100%;
	padding: 32px 10px 33px;
	text-align: center;
	transition: background 0.3s ease-in;
	border: 1px solid #3c3c3c;
	@include respond-to('small') {
		padding: 20px 10px;
	}
	@include respond-to('extra-small') {
		padding: 10px;
	}
	@include respond-to('mobile-m') {
		padding: 10px 5px;
	}
	&:hover,
	&:focus,
	&:active {
		background-color: rgba($color: $accent, $alpha: 1);
		text-decoration: none;
		// box-shadow: 0 0 21px 9px rgba(255, 255, 255, 0.4);
		box-shadow: 0 0 40px rgba(58, 190, 166, 0.25);
		// border: 1px solid $accent;
		border: 1px solid #3c3c3c;
		& .cat-item__title {
			color: #fff;
		}
		& img {
			// filter: invert(100%);
			// transition: filter 0.2s ease;
		}
	}
	&__icon {
		height: 75px;
		vertical-align: bottom;
		@include respond-to('large') {
			// height: 75px;
		}
		@include respond-to('extra-small') {
			height: 60px;
		}
		& img {
			display: block;
			max-height: 100%;
			height: auto;
			margin: 0 auto;
			// max-width: 85px;
		}
	}
	&__title {
		margin-top: 28px;
		font-size: 20px;
		font-weight: 500;
		line-height: 1;
		color: #fff;
		@include respond-to('small') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			margin-top: 15px;
			// font-size: 18px;
		}
	}
}

.cat-nav-slider {
	& .slick-next {
		left: 5px;
	}
	& .slick-prev {
		right: 5px;
	}
}