.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	pointer-events: none;
	// background: #fff;
	// box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	// transition: all 0.4s ease;
}

.header-desktop {
	height: 200px;
	background-image: url(../images/header-back.png);
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	transition: height 0.2s ease; 
	pointer-events: none;
	@include respond-to('medium') {
		display: none;
	}
	& .section-limit {
		position: relative;
		pointer-events: all;
	}
}

.logo {
	position: absolute;
	top: 5px;
	#{$left}: 15px;
	& img {
		max-width: 220px;
		transition: max-width 0.2s ease;
		@include respond-to('larger') {
			max-width: 170px;
		}
		@include respond-to('1100') {
			max-width: 136px;
		}
	}
}

.main-navi {
	display: inline-block;
	& > ul {
		display: inline-block;
		list-style: none;
		margin: side-values(50px 0 0 0);
		padding: 0;
		transition: margin 0.2s ease;
		& > li {
			float: $right;
			margin-#{$left}: 5px;
			position: relative;
			@include respond-to('1100') {
				margin-#{$left}: 2px;
			}
			& > a {
				display: block;
				padding: 0 15px;
				color: #000;
				font-weight: 400;
				line-height: 40px;
				font-size: 20px;
				@include respond-to('larger') {
					padding: 0 10px;
				}
				@include respond-to('large') {
					font-size: 18px;
					line-height: 35px;
				}
				@include respond-to('1100') {
					font-size: 17px;
				}
				&:hover {
					text-decoration: none;
					background-color: rgba($color: $accent, $alpha: 0.7);
					color: #fff;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					background-color: $accent;
					color: #fff;
				}
			}
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		pointer-events: all;
		display: block;
		background-color: #fff;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 3px;
		float: $left;
		& > img {
			width: 78px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

.main-header.scrolling {
	.header-desktop {
		height: 125px;
		background-color: #fff;
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	}
	.main-navi {
		& > ul {
			margin-top: 43px;
		}
	}
	.logo {
		top: 5px;
		& img {
			max-width: 136px;
		}
	}
}








.main-navi {
	// display: inline-block;
	// height: 100%;
	& > ul {
		// display: inline-block;
		list-style: none;
		//   padding:32px 190px 0 0;
		// margin: 0;
		// height: 100%;
		@include respond-to('huge') {
			// padding: 32px 20px 0 0;
		}
		& > li {
			// float: right;
			// position: relative;
			// height: 100%;
			&:hover {
				& > ul {
					display: block;
					opacity: 1;
					transition: 0.4s;
				}
			}
			&:last-child {
				& > span {
					opacity: 0;
					display: none;
				}
			}
			& > a {
				font-size: 17px;
				//   color: #002c5c;
				font-weight: 700;
				@include respond-to('large') {
					font-size: 17px;
				}
			}
			& > ul {
				padding: 0;
				margin: 0;
				list-style: none;
				background-color: #ffffff;
				position: absolute;
				top: 35px;
				right: 0px;
				z-index: 9;
				font-size: 17px;
				opacity: 0;
				display: none;
				transition: 0.4s;
				min-width: 250px;
				text-align: right;
				//   border-left: 1px solid #3d5184;
				@include respond-to('large') {
					top: 31px;
				}
				& > li {
					&:hover {
						background-color: #f4f4f4;
					}
					border-bottom: 1px solid #f3f3f3;
					position: relative;
					& > a {
						border-bottom: 1px solid #e1e1e1;
						transition: 0.3s;
						color: #000;
						padding: 7px 12px;
						display: block;
						opacity: 1;
						&:hover {
							text-decoration: none !important;
						}
					}
					& > ul {
						padding: 0;
						margin: 0;
						list-style: none;
						background-color: #ffffff;
						position: absolute;
						top: 0;
						transition: 0.4s;
						left: 285px;
						z-index: 9;
						opacity: 0;
						display: none;
						min-width: 400px; 
						text-align: center;
						& > li {
							//border-bottom: 1px solid #3a4f82;
							position: relative;
							&:hover {
								background-color: #f4f4f4;
								text-decoration: none !important;
							}
							& > a {
								&:hover {
									text-decoration: none !important;
								}
								transition: 0.3s;

								color: #002c5c;
								padding: 7px 12px;
								display: block;
								opacity: 1;
								border-bottom: 1px solid #f3f3f3;
								text-align: left;
							}
							& > ul {
								padding: 0 20px;
								margin: 0;
								list-style: none;
								background-color: #ffffff;
								position: absolute;
								top: 0;
								transition: 0.4s;
								right: 314px;
								z-index: 9;
								opacity: 0;
								display: none;
								min-width: 315px;
								text-align: center;
								& > li {
									position: relative;
									float: right;
									width: 50%;
									&:nth-child(2n+1) {
										clear: both;
									}
									& > a {
										transition: 0.3s;
										color: #2d2a6e;
										padding: 12px 0px;
										display: block;
										opacity: 1;
										&:hover {
											color: #002c5c;
										}
									}
								}
							}
							&:hover {
								& > ul {
									transition: 0.3s;
									display: block;
									opacity: 1;
								}
							}
						}
					}
					&:hover {
						& > ul {
							transition: 0.3s;
							display: block;
							opacity: 1;
						}
					}
				}

			}
		}
	}
}

//   .main-navi>ul>li>ul>li>a:after{
// 	content: "\203A";

//     position: absolute;
//     right: 5px;
 
//   }

.right-arrow {
	position: absolute;
	top: 34%;
	width: 15px;
	height: 15px;
	right: 9px;
	background-image: url(/~clicktours/assets/images/arrow-right.png);
	background-position: center;
	background-repeat: no-repeat;
	background-position: center;
}