.breadcrumb {
	background-color: transparent;
	margin-bottom: 0;
	padding: 14px 0;

	@include respond-to('small') {
		display: none;
	}
	& li {
		text-shadow: -2px 3px 3px #000;
		font-size: 20px;
		&::before {
			color: #fff;
		}
		& a {
			text-decoration: none;
			color: #fff;
			font-size: 20px;
			font-weight: 300;
		}
	}
	& .active {
		color: #D8BA41;
	}
}
