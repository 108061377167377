footer {
	width: 100%;
	position: relative;
	background-color: #000;
	z-index: 1;
	padding: 50px 0 0px;
	@include respond-to('large') {
		padding: 20px 0 0;
	}
}

.ftr-top {
	padding-bottom: 65px;
	@include respond-to('large') {
		padding-bottom: 30px;
	}
	@include respond-to('small') {
		padding-bottom: 15px;
	}
}

.ftr-bottom {
	overflow: hidden;
	padding: 5px 0;
	background-color: #fff;
}

.site-footer__credit-holder {
	float: $right;
	margin-top: 17px;
	@include respond-to('small') {
		margin-top: 0;
	}
}

.ftr-logos {
	float: $left;
	@include respond-to('small') {
		float: none;
		display: block;
		text-align: center;
	}
	& img {
		max-width: 105px;
	}
}

.wrap-social {
	position: relative;
	float: $left;
	margin-#{$left}: 60px;
	margin-top: 37px;
	@include respond-to('large') {
		margin-#{$left}: 20px;
	}
	@include respond-to('small') {
		margin-#{$left}: 0;
		margin-top: 20px;
	}
	& > a {
		& > i {
			font-size: 19px;
			color: #000;
			margin-#{$left}: 15px;
			@include respond-to('extra-small') {
				margin-#{$left}: 5px;
			}
		}
		&:hover {
			& i {
				color: $accent;
			}
		}
	}
}


.adress-footer{
	margin-top: 25px;
	color: #e3e3e3;
}
.adress-footer a{
	color: #D8BA41;
}
