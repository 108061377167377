
.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 600px;
	max-height: 600px;

	@include respond-to('small') {
		min-height: 500px;
	}
	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: rgba($color: #000000, $alpha: 0.6);
	// }
}

.banner-top-content__image {
	background-position: center center;
	background-size: cover;
	height: 600px;
	@include respond-to('small') {
		height: 500px;
	}
}

.banner-title-block {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 1430px;
	width: 100%;
	padding: 0 15px;
	&__title {
		display: inline-block;
		padding-bottom: 20px;
		border-bottom: 3px solid #D8BA41;
		line-height: 1;
		font-size: 50px;
		text-shadow: -2px 3px 3px #000;
		font-weight: 900;
		color: #D8BA41;
		@include respond-to('small') {
			font-size: 40px;
			padding-bottom: 10px;
		}
		@include respond-to('extra-small') {
			font-size: 35px;

		}
	}
	&__breadcrumbs {}
}

.banner-navs {
	position: absolute;
	z-index: 1;
	left: 50%;
	bottom: 85px;
	transform: translateX(-50%);
	max-width: 1430px;
	width: 100%;
	padding: 0 15px;
	@include respond-to('small') {
		bottom: 15px;
	}
	@include respond-to('extra-small') {
		bottom: 5px;
	}
	& ul {
		margin: 0;
		padding: 0;
		& li {
			display: inline-block;
			margin-#{$left}: 31px;
			@include respond-to('small') {
				margin-#{$left}: 15px;
			}
			@include respond-to('extra-small') {
				margin-#{$left}: 10px;
			}
			& a {
				display: block;
				font-size: 25px;
				font-weight: 300;
				color: #fff;
				padding-bottom: 12px;
				@include link_on-hover(#fff, #D8BA41);
				transition: color 0.2s ease;
				@include respond-to('huge') {
					padding-bottom: 6px;
					margin-bottom: 5px;
				}
				@include respond-to('small') {
					font-size: 20px;
				}
				@include respond-to('extra-small') {
					font-size: 18px;
					padding-bottom: 1px;
				}
				&.active {
					color: #D8BA41;
					border-bottom: 2px solid #D8BA41;
				}
			}
		}
	}
}


.hight-fix{
	height: 100vh;
	max-height: 100%;
}
.banner-title-block{
	top: 75%;
}

.hight-fix  .banner-top-content__image{
    height: 100%;
}
