.ftr-links-koteret {
	font-size: 18px;
	font-weight: 300;
	color: #e3e3e3;
	margin-bottom: 25px;
	padding-bottom: 20px;
	border-bottom: 1px solid #e3e3e3;
	position: relative;
	@include respond-to('medium') {
		margin-top: 15px;
	margin-bottom: 5px;
		padding-bottom: 5px;
	}
	@include respond-to('extra-small') {
		font-size: 14px;
	}
	&:after {
		content: " ";
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		width: 0%;
		height: 1px;
		background-color: $accent;
		margin: 0 auto;
		transition: all 0.4s;
	}
}

.ftr-wrap-links {
	max-width: 200px;
	@include respond-to('small') {
		margin-bottom: 30px;
		margin-left: auto;
		margin-right: auto;
	}
	&:hover {
		div:after {
			width: 100%;
		}
	}
	& > ul {
		padding: 0;
		margin: 0;
		list-style: none;
		& > li {
			margin-bottom: 5px;
			@include respond-to('medium') {
				margin-bottom: 2px;
			}
			& > a {
				font-size: 14px;
				font-weight: 300;
				color: #e3e3e3;
				&:hover {
					color: $accent;
				}
			}
		}
	}
}
